import { Select } from 'antd'
import { useApiUrl, useCustom } from '@refinedev/core'

// const getRemoteSelectorOptions = data => data?.data.map(s => ({ value: typeof s === 'boolean' ? (s ? 'No' : 'Yes') : s }))
const getQuerySelectorOptions = data => data?.data.rows.filter(s => s.name).map(s => ({ label: s.name, value: s.id }))

export const useQuerySelectorProps = ({ path, query, optionsGetter = getQuerySelectorOptions, enabled }) => {
	const apiUrl = useApiUrl()
	const { data, isLoading } = useCustom({
		url: `${apiUrl}${path}`,
		method: 'get',
		config: {
			query
		},
		errorNotification: false,
		queryOptions: {
			enabled: typeof enabled !== 'undefined' ? enabled : !!path
		}
	})

	const options = optionsGetter(data)

	return {
		options,
		isLoading,
		selectorProps: {
			options,
			allowClear: true,
			showSearch: true,
			style: { width: '100%' },
			loading: isLoading
		}
	}
}

export const RemoteSelector = ({ path, query, optionsGetter, ...props }) => {
	const { options, isLoading } = useQuerySelectorProps({
		path: path,
		query,
		optionsGetter: optionsGetter
	})

	return <Select allowClear style={{ width: '100%' }} options={options} loading={isLoading} {...props} />
}
