import dayjs from 'dayjs'

import { useTranslation } from 'react-i18next'
import { ClockCircleOutlined } from '@ant-design/icons'
import { useShow, useParsed, useApiUrl, useCustom } from '@refinedev/core'
import { Row, Col, Card, Grid, Image, Space, Button, Divider, Typography } from 'antd'

import Warning from '../components/warning'
import TagsRow from '../components/tagsRow'
import KeyValue from '../components/display/keyValue'
import DealProgress from '../components/dealProgress'
import IcsButton from '../components/buttons/icsButton'
import BreadcrumbTitle from '../components/breadcrumbTitle'
import CommitWrapper from '../components/commits/commitModal'
import RecordLangHtmlParagraph from '../components/display/recordHtmlParagraph'

import { I18N_DEAL_NS } from '../i18n'
import { formatCurrency } from '../utils/formatting'
import { getDealHeaderTags, stages } from './showcase'
import { getFlagEmoji } from './settings/profile/languages'
import { grey, greyBackground, greyBorder, paragraphStyle, violetDark } from '../theme'

const { useBreakpoint } = Grid
const { Title, Paragraph, Text, Link } = Typography

const vertSpaceProps = { direction: 'vertical', style: { width: '100%' } }

export const InfoCard = ({ children }) => {
	const { xs } = useBreakpoint()

	return (
		<Card
			style={{
				border: null,
				borderRadius: 10,
				backgroundColor: !xs ? greyBackground : 'white'
			}}
		>
			{children}
		</Card>
	)
}

const LayoutRow = ({ firstColumn, secondColumn, ...props }) => {
	const { xs } = useBreakpoint()

	return !xs ? (
		<Row gutter={[72, 72]} {...props}>
			<Col span={14}>{firstColumn}</Col>
			<Col span={10}>{secondColumn}</Col>
		</Row>
	) : (
		<Space {...vertSpaceProps} size='large' {...props}>
			{firstColumn}
			{secondColumn}
		</Space>
	)
}

const CommitButton = ({ record: deal }) => {
	const { t } = useTranslation([I18N_DEAL_NS])

	// awkward query key because is isn't possible to set custom query key at useShow and useList
	// todo: make adequate key
	const queryKeyToInvalidate = ['default', 'deals', 'detail', deal?.id, {}]
	const openCommitModal = CommitWrapper.useCommitModal()

	if (deal?.state !== 'collect_commits') return null

	return (
		<Button type='primary' onClick={() => openCommitModal(deal, queryKeyToInvalidate)} style={{ width: '100%' }}>
			{t(!deal?.investment_id ? 'commit_make' : 'commit_change')}
		</Button>
	)
}

const DealHeaderDescription = ({ record: { logo, url, ...record } = {} }) => (
	<Space {...vertSpaceProps} size='middle'>
		{logo && <Image src={`data:image/png;base64, ${logo}`} alt='Logo' width={200} />}
		<RecordLangHtmlParagraph record={record} field='short_desc' />
		{url && (
			<Link strong href={`https://${url}`}>
				{url}
			</Link>
		)}
	</Space>
)

const DealHeaderInfoBody = ({
	record: {
		stage,
		syndicators,
		allocation,
		soft_commitment,
		hard_commitment,
		wait_list_commitment,
		ad_committed,
		...record
	} = {}
}) => (
	<Space {...vertSpaceProps} size='large'>
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<KeyValue i18nNS={[I18N_DEAL_NS]} i18nKey='stage' value={stages[stage]?.title} />
			<KeyValue i18nNS={[I18N_DEAL_NS]} i18nKey='syndicator' value={syndicators?.join(', ')} />
		</div>
		{record.state !== 'completed' ? (
			<DealProgress {...{ allocation, soft_commitment, hard_commitment, wait_list_commitment }} />
		) : (
			ad_committed && (
				<Paragraph style={{ margin: 0 }}>
					<Text strong>Total amount: </Text>
					<Text>{formatCurrency(ad_committed)}</Text>
				</Paragraph>
			)
		)}
		<div style={{ width: 'fit-content' }}>
			<CommitButton record={record} />
		</div>
	</Space>
)

const DealHeaderInfo = ({ record }) => (
	<Space {...vertSpaceProps} size='large'>
		<TagsRow tags={record && getDealHeaderTags(record)} justify='start' />
		<DealHeaderInfoBody record={record} />
	</Space>
)

const DealHeader = ({ record }) => {
	const { xs } = useBreakpoint()

	return !xs ? (
		<LayoutRow
			firstColumn={<DealHeaderDescription record={record} />}
			secondColumn={<DealHeaderInfo record={record} />}
		/>
	) : (
		<Space {...vertSpaceProps} size='large'>
			<TagsRow tags={record && getDealHeaderTags(record)} justify='start' />
			<DealHeaderDescription record={record} />
			<Card style={{ border: `1px solid ${greyBorder}` }}>
				<DealHeaderInfoBody record={record} />
			</Card>
		</Space>
	)
}

const getSmallTalkGoogleCalendarHref = (deal_name, small_talk_time, small_talk_url) => {
	const url = 'http://www.google.com/calendar/render?action=TEMPLATE'
	const qs = {
		text: `${deal_name} Small talk`,
		dates: small_talk_time, // 1-hour by default
		details: small_talk_url || '',
		rcp: false
	}

	return Object.entries(qs).reduce((acc, [k, v]) => `${acc}&${k}=${v}`, url)
}

const DealSmallTalk = ({
	record: { id: deal_id, name: deal_name, small_talk_time, small_talk_url, small_talk_record_url } = {}
}) => {
	const { t } = useTranslation([I18N_DEAL_NS])

	return (
		<Space {...vertSpaceProps} size='large'>
			<Space {...vertSpaceProps} size='small'>
				<Title level={3}>{t('small_talk')}</Title>
				{small_talk_time && (
					<Space>
						<ClockCircleOutlined />
						<Paragraph style={{ ...paragraphStyle, marginBottom: 1 }}>
							{dayjs(small_talk_time).format('LL [at] HH:MM ([GMT] Z)')}
						</Paragraph>
					</Space>
				)}
			</Space>
			{small_talk_record_url && (
				<Button type='dashed' target='_blank' href={small_talk_record_url}>
					{t('zoom_record_link')}
				</Button>
			)}
			{!small_talk_record_url && (
				<Space size='middle' wrap>
					{small_talk_url && (
						<Button type='dashed' target='_blank' href={small_talk_url}>
							{t('zoom_link')}
						</Button>
					)}
					{small_talk_time && (
						<Button
							type='dashed'
							rel='nofollow'
							target='_blank'
							href={getSmallTalkGoogleCalendarHref(deal_name, small_talk_time, small_talk_url)}
						>
							{t('add_to_google')}
						</Button>
					)}
					{small_talk_time && (
						<IcsButton type='dashed' target='_blank' dealId={deal_id}>
							{t('get_ics')}
						</IcsButton>
					)}
				</Space>
			)}
		</Space>
	)
}

const ConditionTitle = ({ title, prefix }) => (
	<Title level={3} style={{ margin: 0 }}>
		<Text style={{ color: grey }}>{prefix}</Text>
		<Text style={{ color: violetDark }}>{title}</Text>
	</Title>
)

const DealConditionCol = ({ span, title, valueKey, record, render = v => v }) =>
	record &&
	record[valueKey] !== undefined && (
		<Col span={span}>
			<Space {...vertSpaceProps} size='small'>
				<ConditionTitle title={render(record[valueKey])} />
				<Paragraph style={{ ...paragraphStyle }}>{title}</Paragraph>
			</Space>
		</Col>
	)

const FloatingFees = ({ fees }) =>
	fees && fees.length ? (
		<Space {...vertSpaceProps} size='small'>
			{fees.map(({ threshold, sf_synd = 0, ci_synd = 0 }) => (
				<ConditionTitle
					key={`${threshold}`}
					title={`${sf_synd * 100}% setup fee / ${ci_synd * 100}% carry`}
					prefix={fees.length > 1 ? `<${threshold !== undefined ? formatCurrency(threshold) : '∞'} — ` : ''}
				/>
			))}
		</Space>
	) : null

const DealConditions = ({ record }) => {
	const { t } = useTranslation([I18N_DEAL_NS])

	return (
		<Space {...vertSpaceProps} size='small'>
			<Title level={3}>{t('conditions')}</Title>
			<Space {...vertSpaceProps} size='middle'>
				{/* <ConditionTitle title={t('valuation')} /> */}
				<RecordLangHtmlParagraph record={record} field='offer' />
				<InfoCard>
					<Row gutter={[16, 16]}>
						<DealConditionCol
							span={6}
							title={t('allocation_angelsdeck')}
							valueKey='allocation'
							render={formatCurrency}
							record={record}
						/>
						<DealConditionCol
							span={6}
							title={t('min_cheque')}
							valueKey='min_commit_amount'
							render={formatCurrency}
							record={record}
						/>
						<DealConditionCol
							span={12}
							title={t('invest_deadline')}
							valueKey='commit_deadline'
							render={v => dayjs(v).format('ll')}
							record={record}
						/>
					</Row>
				</InfoCard>
				{/* <ConditionTitle title={t('invest_synd_size')} />
				<RecordLangHtmlParagraph record={record} field='keypoints' /> */}
				<FloatingFees {...record} />
				<RecordLangHtmlParagraph record={record} field='conditions' />
			</Space>
		</Space>
	)
}

const DealMaterialsLinkButton = ({ recordUrl, title }) =>
	recordUrl && (
		<Button type='dashed' href={recordUrl} target='_blank'>
			{title}
		</Button>
	)

const MaterialFrame = ({ url }) =>
	url && (
		<div style={{ width: '100%', height: '200px', overflow: 'hidden' }}>
			<iframe
				title='material'
				src={url}
				style={{
					border: 'none',
					borderRadius: 10,
					width: '200%',
					height: '200%',
					transform: 'scale(0.5)',
					transformOrigin: '0 0'
				}}
			/>
		</div>
	)

const DealMaterials = ({ record: { id } = {} }) => {
	const { t } = useTranslation([I18N_DEAL_NS])

	const apiUrl = useApiUrl()
	const { data: { data } = {}, isLoading } = useCustom({
		url: `${apiUrl}/deals/${id}/materials`
	})

	if (isLoading || !data?.length) return null

	const materialFrameUrl = data[0]?.url

	return (
		<Space {...vertSpaceProps} size='large'>
			<InfoCard>
				<Title level={3}>{t('materials')}</Title>
				<Space {...vertSpaceProps} size='large'>
					<Space size='middle' wrap>
						{data?.map(({ name, url }) => (
							<DealMaterialsLinkButton recordUrl={url} title={name} key={name} />
						))}
					</Space>
					<MaterialFrame url={materialFrameUrl} />
				</Space>
			</InfoCard>
		</Space>
	)
}

const DealConditionsMaterials = ({ record }) => (
	<LayoutRow firstColumn={<DealConditions record={record} />} secondColumn={<DealMaterials record={record} />} />
)

const ChatButtons = ({ chat_links }) => {
	const { t } = useTranslation([I18N_DEAL_NS])

	return (
		<Space>
			{chat_links &&
				Object.entries(chat_links).map(([lang, url]) => (
					<Button key={lang} href={url} target='_blank'>
						<Space>
							{getFlagEmoji(lang)}
							{t('goto_chat')}
						</Space>
					</Button>
				))}
		</Space>
	)
}

const DealButtonsWarning = ({ record }) => (
	<Space direction='vertical' size='middle' style={{ width: '100%', marginTop: 16 }}>
		<Space>
			<CommitButton record={record} />
			<ChatButtons chat_links={record?.chat_links} />
		</Space>
		<Warning />
	</Space>
)

// const DealEvaluation = () => {
// 	const { t } = useTranslation([I18N_DEAL_NS])

// 	return (
// 		<Space {...vertSpaceProps} size='middle'>
// 			<Title level={3} style={{ marginBottom: 0 }}>
// 				{t('evaluation_title')}
// 			</Title>
// 			<Rate />
// 			<Row gutter={[8, 8]}>
// 				<Col flex='auto'>
// 					<Input placeholder={t('evaluation_placeholder')} />
// 				</Col>
// 				<Col>
// 					<Button type='primary'>{t('send')}</Button>
// 				</Col>
// 			</Row>
// 		</Space>
// 	)
// }

const HideableDivider = () => {
	const { xs } = useBreakpoint()

	return !xs && <Divider />
}

const DealPageBody = ({ record }) => {
	const { xs } = useBreakpoint()

	const { t } = useTranslation([I18N_DEAL_NS])

	const apiUrl = useApiUrl()
	const { data: { data: { default_commit_vehicle_id, default_commit_amount, default_commit_type } = {} } = {} } =
		useCustom({
			url: `${apiUrl}/investors/me`,
			errorNotification: false
		})

	const defaults = { default_commit_vehicle_id, default_commit_amount, default_commit_type }

	return (
		<Space {...vertSpaceProps} size={!xs ? 'small' : 'large'}>
			<DealHeader record={record} />
			{/* <ReportsCard {...record} /> */}
			<HideableDivider />
			<LayoutRow
				firstColumn={
					<RecordLangHtmlParagraph record={record} field='keypoints' title={t('investment_thesis')} />
				}
				secondColumn={<DealSmallTalk record={record} />}
			/>
			<HideableDivider />
			{/* <LayoutRow
				firstColumn={
					<Space {...vertSpace} size='large'>
						<RecordLangHtmlParagraph record={record} field='keypoints' title={t('team')} />
						<RecordLangHtmlParagraph record={record} field='keypoints' title={t('goals')} />
					</Space>
				}
				secondColumn={<RecordLangHtmlParagraph record={record} field='keypoints' title={t('round')} />}
			/>
			<HideableDivider /> */}
			<DealConditionsMaterials record={record} />
			<DealButtonsWarning record={record} defaults={defaults} />
			{/* <HideableDivider /> */}
			{/* <DealEvaluation record={record} /> */}
		</Space>
	)
}

const DealPage = () => {
	const { id } = useParsed()
	const { queryResult } = useShow({ resource: 'deals', id, queryOptions: { queryKey: ['deal'] } })
	const {
		data
		// isLoading
	} = queryResult
	const record = data?.data

	const { xs } = useBreakpoint()

	return (
		record &&
		(!xs ? (
			<Space {...vertSpaceProps} size='large'>
				<BreadcrumbTitle current={`${record?.name || ''} ${record?.number || ''}`} />
				<Card>
					<DealPageBody record={record} />
				</Card>
			</Space>
		) : (
			<DealPageBody record={record} style={{ padding: '10px' }} />
		))
	)
}

export default DealPage
