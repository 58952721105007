import { useState, useEffect } from 'react'

import { FilterableSelect } from './simpleSelects'

const CancellableSelect = ({ options, cancelOptionKey = null, value, onChange, ...props }) => {
	const [selected, setSelected] = useState(value)

	useEffect(() => {
		onChange(selected)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected])

	const selectHandler = value => {
		if (value === cancelOptionKey) {
			setSelected([cancelOptionKey])
		} else {
			setSelected(selected?.includes(cancelOptionKey) ? [value] : [value, ...(selected || [])])
		}
	}

	const deselectHandler = value => setSelected(selected?.filter(v => v !== value))

	return <FilterableSelect onSelect={selectHandler} onDeselect={deselectHandler} value={selected || []} options={options} {...props} />
}

export default CancellableSelect
