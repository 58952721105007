import { Menu } from 'antd'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useMenu } from '@refinedev/core'
import { useTranslation } from 'react-i18next'
import { BookOutlined } from '@ant-design/icons'

import { pages } from '../pages'

import { I18N_GENERIC_NS, I18N_SIDER_NS, manualHref } from '../i18n'

const { SubMenu, Item } = Menu

const renderMenu = (pages, t, { parents = [], onlyIcons = false }) =>
	Object.entries(pages)
		.filter(([_, { notIncludeInMenu }]) => !notIncludeInMenu)
		.map(([key, { path, element, icon, withDivider, hidden, nested }]) => {
			if (hidden || key?.[0] === ':') return null
			const isSubMenu = !element && nested
			const itemPath = `${parents.length ? '/' + parents.join('/') : ''}/${path === undefined ? key : path}`

			return (
				<Fragment key={key}>
					{isSubMenu ? (
						<SubMenu
							icon={icon}
							key={itemPath}
							title={!onlyIcons && t(key)}
							popupOffset={onlyIcons ? [-80, -200] : null}
						>
							{renderMenu(nested, t, { parents: [...parents, path == null ? key : path] })}
						</SubMenu>
					) : (
						<Item key={itemPath} icon={icon}>
							<Link to={itemPath} style={{ color: 'white' }}>
								{!onlyIcons ? t(key) || key : null}
							</Link>
						</Item>
					)}
					{withDivider && <Menu.Divider />}
				</Fragment>
			)
		})

const RenderedMenu = ({ onlyIcons, ...props }) => {
	const { selectedKey } = useMenu()

	const { t } = useTranslation([I18N_SIDER_NS])

	return (
		<Menu mode='inline' selectedKeys={[selectedKey]} defaultOpenKeys={['showcase']} {...props}>
			{renderMenu(pages, t, { onlyIcons })}
		</Menu>
	)
}

export const SiderBottomMenu = ({ ...props }) => {
	const { t } = useTranslation([I18N_GENERIC_NS])

	return (
		<Menu mode='inline' style={{ marginBottom: 8 }} selectedKeys={[]} {...props}>
			<Item icon={<BookOutlined style={{ fontSize: 18 }} />} key='manual'>
				<Link to={manualHref} target='_blank' style={{ color: 'white' }}>
					{t('manual')}
				</Link>
			</Item>
		</Menu>
	)
}

export default RenderedMenu
