import { Space, Typography } from 'antd'

import { getFirstValue } from '../../utils/helpers'
import { paragraphStyle } from '../../theme'

import './brDisable.css'

const { Paragraph, Title } = Typography

const RecordLangHtmlParagraph = ({ record, field, title }) => (
	<Space direction='vertical' size='small'>
		{title && <Title level={3}>{title}</Title>}
		<Paragraph style={{ display: 'flex', margin: 0 }}>
			<span style={{ ...paragraphStyle }} dangerouslySetInnerHTML={{ __html: getFirstValue(record?.[field]) }} />
		</Paragraph>
	</Space>
)

export default RecordLangHtmlParagraph
