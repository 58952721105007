import { Typography, Progress as AntdProgress, Row } from 'antd'

import { greenBright, grey } from '../theme'

const { Paragraph } = Typography

const Progress = ({ title, firstValue, secondValue, percent = 0, status = 'active', size = 'small' }) => (
	<div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
		{/* <Paragraph type='secondary' style={{ margin: 0 }}>
			{title}
		</Paragraph> */}
		<Row justify='space-between'>
			<Paragraph style={{ margin: 0, color: greenBright, fontSize: 12 }} strong>
				{firstValue}
			</Paragraph>
			<Paragraph style={{ margin: 0, color: grey, fontSize: 12 }} strong>
				{secondValue}
			</Paragraph>
		</Row>
		<AntdProgress size={size} percent={percent} status={status} showInfo={false} strokeColor={greenBright} />
	</div>
)

export default Progress
