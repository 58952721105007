import { Grid, Layout } from 'antd'
import Icon from '@ant-design/icons'
import { useNavigation } from '@refinedev/core'

import Menu, { SiderBottomMenu } from './menu'

import { ReactComponent as Logo } from '../icons/adgvLogo.svg'
import { ReactComponent as ShortLogo } from '../icons/adgvShortLogo.svg'

const { useBreakpoint } = Grid

const Sider = () => {
	const { xs, lg } = useBreakpoint()

	const { push } = useNavigation()

	if (xs) return
	return (
		<Layout.Sider
			// collapsible
			breakpoint='lg'
			style={{
				top: 0,
				left: 0,
				height: '100vh',
				overflow: 'auto',
				position: 'sticky',
				background:
					'linear-gradient(0deg, rgba(26, 32, 46, 0.60) 0%, rgba(26, 32, 46, 0.60) 100%), url(/adgvBackground.png), lightgray 50% / cover no-repeat',
				backgroundSize: 'cover',
				backgroundPosition: '47.5%',
				backgroundColor: '#3f2571'
			}}
		>
			<div
				style={{
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between'
				}}
			>
				<div>
					<div
						onClick={() => push('/')}
						style={{
							height: 72,
							display: 'flex',
							cursor: 'pointer',
							alignItems: 'center',
							justifyContent: 'center'
						}}
					>
						<Icon component={lg ? Logo : ShortLogo} style={{ fontSize: lg ? 136 : 36, marginTop: 16 }} />
					</div>
					<Menu theme='dark' />
				</div>
				<SiderBottomMenu theme='dark' />
			</div>
		</Layout.Sider>
	)
}

export default Sider
