import { useParsed, useShow } from '@refinedev/core'

import { PortfolioPageBody } from './portfolio'
import { Space, Tag } from 'antd'

const FundPage = () => {
	const { id: fundDealId } = useParsed()

	const { queryResult } = useShow({ resource: 'deals', id: fundDealId })
	const { data } = queryResult
	const record = data?.data || {}

	const { name, number } = record
	const currentName = `${name} ${number}`
	const currentTitleComponent = (
		<Space>
			{currentName}
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Tag color='purple'>Fund</Tag>
			</div>
		</Space>
	)

	return (
		<PortfolioPageBody
			isFund
			current={currentName}
			fundCardData={record}
			resource={`deals/${fundDealId}`}
			currentTitleComponent={currentTitleComponent}
		/>
	)
}

export default FundPage
