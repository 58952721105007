import { useEffect } from 'react'
import { Row, Select, Form } from 'antd'
import { useForm } from '@refinedev/antd'

import { updateLang } from '../../../i18n'

import { SettingsFieldColWrapper } from '.'

const { Item } = Form

const selectable_langs = ['en', 'ru', 'es', 'cn', 'ae']
const ui_langs = ['en', 'ru']
const langs = {
	en: 'English',
	ru: 'Russian',
	es: 'Spanish',
	cn: 'Chinese',
	ae: 'Arabic'
}

export const getFlagEmoji = countryCode =>
	String.fromCodePoint(
		...[...(countryCode === 'en' ? 'gb' : countryCode).toUpperCase()].map(x => 0x1f1a5 + x.charCodeAt())
	)

const getLangLabel = lang => `${getFlagEmoji(lang)} ${langs[lang]}`

const getLangsLabels = l => l.map(lang => ({ value: lang, label: getLangLabel(lang) }))

const Languages = ({ enrichSaveHandler }) => {
	const {
		formProps,
		saveButtonProps: { onClick: saveHandler }
	} = useForm({
		id: 'me',
		action: 'edit',
		resource: 'investors',
		warnWhenUnsavedChanges: false,
		onMutationSuccess: (_, variables) => {
			updateLang(variables?.ui_lang)
		}
	})

	useEffect(() => enrichSaveHandler(saveHandler, 'languages'), [enrichSaveHandler, saveHandler])

	return (
		<Form {...formProps}>
			<Row gutter={[32, 32]}>
				<SettingsFieldColWrapper name='ui_lang'>
					<Item name={['ui_lang']} style={{ margin: 0 }}>
						<Select style={{ width: '100%' }} options={getLangsLabels(ui_langs)} />
					</Item>
				</SettingsFieldColWrapper>
				<SettingsFieldColWrapper name='spoken_langs'>
					<Item name={['spoken_langs']} style={{ margin: 0 }}>
						<Select mode='multiple' style={{ width: '100%' }} options={getLangsLabels(selectable_langs)} />
					</Item>
				</SettingsFieldColWrapper>
				<SettingsFieldColWrapper name='showcase_langs'>
					<Item name={['showcase_langs']} style={{ margin: 0 }}>
						<Select mode='multiple' style={{ width: '100%' }} options={getLangsLabels(selectable_langs)} />
					</Item>
				</SettingsFieldColWrapper>
			</Row>
		</Form>
	)
}

export default Languages
