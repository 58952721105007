// import { geekblue } from '@ant-design/colors'
import { useNavigate } from 'react-router-dom'
import { useGetIdentity } from '@refinedev/core'
import { Avatar, Layout as AntdLayout, Space, Typography } from 'antd'

import { defaultShadow } from '../theme'
import { I18N_GENERIC_NS } from '../i18n'
import { useTranslation } from 'react-i18next'

const { Text } = Typography

const ProfileCard = ({ investorId, fullName, additional_role }) => {
	const navigate = useNavigate()

	const { t } = useTranslation([I18N_GENERIC_NS])

	return (
		<Space
			size='middle'
			onClick={() => navigate('settings/profile')}
			style={{ width: 185, display: 'flex', alignItems: 'center', cursor: 'pointer' }}
		>
			{investorId && (
				<Avatar
					src={`${process.env.REACT_APP_BOT_API_URL}/investors/${investorId}/avatar?${Date.now()}`}
					style={{ height: 35, width: 35, border: null }}
				/>
			)}
			<div
				style={{
					height: 32,
					display: 'flex',
					margin: '0 1px 1px 0',
					flexDirection: 'column',
					justifyContent: 'space-between'
				}}
			>
				{fullName && <Text style={{ fontSize: 12, fontWeight: 700, lineHeight: '14px' }}>{fullName}</Text>}
				<Text style={{ fontSize: 11, fontWeight: 700, lineHeight: '14px' }}>{t(additional_role || 'investor')}</Text>
			</div>
		</Space>
	)
}

const Header = () => {
	const { data, fetchStatus } = useGetIdentity()
	const { investor_id, full_name, team_member_of_investor_id } = data || {}

	const additional_role = team_member_of_investor_id && 'team_member'

	return (
		<AntdLayout.Header
			style={{
				height: '64px',
				display: 'flex',
				padding: '0px 24px',
				alignItems: 'center',
				backgroundColor: 'white',
				boxShadow: defaultShadow,
				justifyContent: 'flex-end'
			}}
		>
			{/* fetchStatus is used to force update of avatar */}
			{/* // TODO: avatar should be cached when not updated */}
			{fetchStatus === 'idle' && <ProfileCard investorId={investor_id} fullName={full_name} additional_role={additional_role} />}
		</AntdLayout.Header>
	)
}

export default Header
