import { Space } from 'antd'

import SearchInput from '../components/inputs/searchInput'
import BreadcrumbTitle from '../components/breadcrumbTitle'

const ControlPanelHeader = ({ onSearch, onSort, ...breadcrumbProps }) => {
	return (
		<Space size='large' style={{ display: 'flex', justifyContent: 'space-between' }}>
			<BreadcrumbTitle {...breadcrumbProps} />
			<Space size='middle'>
				{onSearch && <SearchInput onSearch={onSearch} />}
				{/* {onSort && <Select placeholder='Sort by' />} */}
			</Space>
		</Space>
	)
}

export default ControlPanelHeader
