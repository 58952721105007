import { Grid, Layout } from 'antd'

import Menu from './menu'

const { useBreakpoint } = Grid

const Footer = () => {
	const { xs } = useBreakpoint()
	if (!xs) return

	return (
		<Layout.Footer
			style={{
				position: 'sticky',
				bottom: 0,
				zIndex: 1,
				width: '100%',
				padding: 12,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: '#3f2571',
				boxShadow: '0px 3px 8px 0px rgba(34, 60, 80, 0.2)'
			}}
		>
			<Menu mode='horizontal' theme={null} onlyIcons />
		</Layout.Footer>
	)
}

export default Footer
