import { useState, useEffect, useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { CheckOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { useGetIdentity, useUpdate } from '@refinedev/core'
import { Avatar as AntdAvatar, Button, Grid, Space, Upload } from 'antd'

import { I18N_SETTINGS_NS } from '../../../i18n'

const { useBreakpoint } = Grid

const getBase64 = file =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = error => reject(error)
	})

const Avatar = ({ enrichSaveHandler }) => {
	const queryClient = useQueryClient()

	const { xs } = useBreakpoint()
	const { t } = useTranslation([I18N_SETTINGS_NS])

	const { data: user } = useGetIdentity()
	const { mutate } = useUpdate()

	const [fileList, setFileList] = useState()
	const [isTelegramPhotoUsage, setIsTelegramPhotoUsage] = useState(false)

	const beforeUploadHandler = async file => {
		setIsTelegramPhotoUsage(false)
		file.url = await getBase64(file)
		setFileList([file])
	}

	const saveHandler = useCallback(
		() =>
			(isTelegramPhotoUsage || fileList?.length) &&
			mutate(
				{
					resource: 'investors',
					id: 'me',
					values: {
						avatar: isTelegramPhotoUsage
							? 'use_tg_picture'
							: fileList?.length && fileList[0].url.split(',')[1]
					},
					successNotification: false
				},
				{
					onSuccess: () => queryClient.invalidateQueries(['getUserIdentity'])
				}
			),
		[queryClient, mutate, fileList, isTelegramPhotoUsage]
	)

	useEffect(() => enrichSaveHandler(saveHandler, 'avatar'), [enrichSaveHandler, saveHandler])

	const avatarImgSrc = isTelegramPhotoUsage
		? user?.photo_url
		: fileList?.[0]?.['url'] ||
		  (user?.investor_id &&
				`${process.env.REACT_APP_BOT_API_URL}/investors/${user.investor_id}/avatar?${Date.now()}`)

	return (
		<Space size='large' style={{ marginBottom: 24 }}>
			<AntdAvatar shape='circle' size={142} src={avatarImgSrc} />
			<Space
				direction={!xs ? 'horizontal' : 'vertical'}
				size={!xs ? 'large' : 'small'}
				align='start'
				style={{ marginBottom: 48 }}
			>
				<Upload
					maxCount={1}
					multiple={false}
					fileList={fileList}
					action={getBase64}
					beforeUpload={beforeUploadHandler}
				>
					<Button name='file' type='dashed'>
						{t('update_photo')}
					</Button>
				</Upload>
				<Button
					type='dashed'
					onClick={() => setIsTelegramPhotoUsage(true)}
					icon={isTelegramPhotoUsage && <CheckOutlined />}
				>
					{t('use_telegram_avatar')}
				</Button>
			</Space>
		</Space>
	)
}

export default Avatar
