import { Button } from 'antd'
import { useApiUrl } from '@refinedev/core'
import { useTranslation } from 'react-i18next'

import { I18N_DEAL_NS } from '../../i18n'
import { getAxiosHeadersFromLocalStorage } from '../../utils/auth-provider'

const IcsButton = ({ dealId }) => {
	const { t } = useTranslation([I18N_DEAL_NS])

	const apiUrl = useApiUrl()

	const getIcsHandler = async () => {
		try {
			const response = await fetch(`${apiUrl}/deals/${dealId}/export_small_talk`, {
				method: 'GET',
				...(await getAxiosHeadersFromLocalStorage())
			})
			const blob = await response.blob()

			const url = window.URL.createObjectURL(blob)
			const link = document.createElement('a')
			link.href = url
			// link.setAttribute('download', 'meetup.ics')

			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)

			window.URL.revokeObjectURL(url)
		} catch (error) {
			console.error('Error downloading file', error)
		}
	}

	return (
		<Button type='dashed' onClick={() => getIcsHandler()} href={null}>
			{t('get_ics')}
		</Button>
	)
}

export default IcsButton
