import { useEffect } from 'react'
import { useLogout } from '@refinedev/core'

import axios from 'axios'

export const axiosInstance = axios.create()
axiosInstance.interceptors.request.use(request => {
	request.headers['Telegram-Data'] = encodeURI(localStorage.getItem('tgUserData'))
	return request
})

export const AxiosInterceptor = ({ children }) => {
	const { mutate: logout } = useLogout()

	useEffect(() => {
		const resInterceptor = response => {
			return response
		}

		const errInterceptor = error => {
			if (error.response?.status === 401) {
				logout()
			}

			// return Promise.resolve(error.response)
			return Promise.reject(error)
		}
		const interceptor = axiosInstance.interceptors.response.use(resInterceptor, errInterceptor)
		return () => axiosInstance.interceptors.response.eject(interceptor)
	}, [logout])

	return children
}
