import { Button, Result } from 'antd'
import { useTranslation } from 'react-i18next'

import { I18N_GENERIC_NS } from '../i18n'

const UnapprovedPage = () => {
	const { t } = useTranslation(I18N_GENERIC_NS)

	return (
		<Result
			title={t('unapproved')}
			extra={
				<Button href={`https://t.me/${process.env.REACT_APP_BOT_NAME}?start=restart`}>{t('continue')}</Button>
			}
		/>
	)
}

export default UnapprovedPage
