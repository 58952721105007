import { Typography } from 'antd'

import { grey } from '../theme'
import { useTranslation } from 'react-i18next'

import { I18N_GENERIC_NS } from '../i18n'

const { Paragraph } = Typography

const Warning = () => {
	const { t } = useTranslation([I18N_GENERIC_NS])

	return (
		<Paragraph style={{ color: grey, fontSize: 10, lineHeight: '130%', fontWeight: 400, whiteSpace: 'pre-line' }}>
			{t('warning')}
		</Paragraph>
	)
}

export default Warning
