import { Area } from '@ant-design/plots'

import { secondaryColor } from '../../theme'
import { formatCurrency } from '../../utils/formatting'

const config = {
	xField: 'label',
	yField: 'valuation',
	label: false,
	xAxis: {
		label: {
			autoHide: true,
			autoRotate: false
		}
	},
	yAxis: {
		label: {
			formatter: v => formatCurrency(v)
		}
	},
	meta: {
		quarters: {
			alias: 'Quarter'
		},
		valuation: {
			alias: 'Valuation'
		}
	},
	scrollbar: {
		type: 'horizontal'
	},
	color: secondaryColor,
	areaStyle: () => ({
		fill: `l(90) 0:${secondaryColor} 1:rgba(255, 255, 255, 0)`
	}),
	tooltip: {
		formatter: datum => ({ title: 'Valuation', name: datum.label, value: formatCurrency(datum.valuation) })
	}
}

// x = date, y = number
const QuarterColumn = ({ data }) => (data ? <Area {...config} data={data} /> : null)

export default QuarterColumn
