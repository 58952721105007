import { useTranslation } from 'react-i18next'
import { Typography } from 'antd'

const { Text } = Typography

const KeyValue = ({ i18nNS, i18nKey, value }) => {
	const { t } = useTranslation(i18nNS)

	return (
		i18nKey &&
		value !== undefined && (
			<Text>
				<Text strong>{t(i18nKey)}:</Text> {value}
			</Text>
		)
	)
}

export default KeyValue
