import { useEffect } from 'react'
import { ConfigProvider } from 'antd'
import TagManager from 'react-gtm-module'
import { Authenticated, Refine } from '@refinedev/core'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import { ErrorComponent, notificationProvider, ThemedLayout } from '@refinedev/antd'
import routerBindings, {
	CatchAllNavigate,
	// NavigateToResource,
	UnsavedChangesNotifier
} from '@refinedev/react-router-v6'

import apiProvider from './utils/provider'
import { resources } from './utils/resources'
import { authProvider } from './utils/auth-provider'
import { axiosInstance, AxiosInterceptor } from './utils/axios-interceptor'

import Sider from './layout/sider'
import Header from './layout/header'
import Footer from './layout/footer'
import UnapprovedPage from './pages/unapproved'
import CommitWrapper from './components/commits/commitModal'
import pages, { getPagesRoutes, LoginPage, LogoutPage } from './pages'

import antdTheme from './theme'

import '@refinedev/antd/dist/reset.css'
import './globals.css'

const refineOptions = {
	syncWithLocation: true,
	warnWhenUnsavedChanges: true,
	reactQuery: {
		clientConfig: {
			defaultOptions: {
				queries: {
					retry: false
				},
				mutations: {
					retry: false
				}
			}
		}
	},
	disableTelemetry: true
}

const App = () => {
	useEffect(() => {
		const gtmId = process.env.REACT_APP_GTM_ID
		if (gtmId) TagManager.initialize({ gtmId })
	}, [])

	return (
		<BrowserRouter>
			<ConfigProvider theme={antdTheme}>
				<Refine
					dataProvider={apiProvider(process.env.REACT_APP_BOT_API_URL, axiosInstance)}
					notificationProvider={notificationProvider}
					routerProvider={routerBindings}
					authProvider={authProvider}
					options={refineOptions}
					resources={resources}
				>
					<AxiosInterceptor>
						<Routes>
							<Route
								element={
									<Authenticated fallback={<CatchAllNavigate to='/login' />}>
										<ThemedLayout Header={Header} Sider={Sider} Footer={Footer}>
											<CommitWrapper>
												<Outlet />
											</CommitWrapper>
										</ThemedLayout>
									</Authenticated>
								}
							>
								{getPagesRoutes(pages)}
								<Route path='/logout' element={<LogoutPage />} />
							</Route>
							<Route
								element={
									<Authenticated fallback={<Outlet />}>
										{/* <NavigateToResource /> */}
										<Outlet />
									</Authenticated>
								}
							>
								<Route path='/login' element={<LoginPage />} />
								<Route path='/unapproved' element={<UnapprovedPage />} />
							</Route>
							<Route
								element={
									<Authenticated>
										<Outlet />
									</Authenticated>
								}
							>
								<Route path='*' element={<ErrorComponent />} />
							</Route>
						</Routes>
						<UnsavedChangesNotifier />
					</AxiosInterceptor>
				</Refine>
			</ConfigProvider>
		</BrowserRouter>
	)
}

export default App
