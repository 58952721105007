import dayjs from 'dayjs'
import { useEffect } from 'react'
import { useForm } from '@refinedev/antd'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'
import { useApiUrl, useCustom } from '@refinedev/core'
import { Form, Row, Input, Select, DatePicker } from 'antd'

import CityInput from '../../../components/selects/cityInput'
import CustomAutoComplete from '../../../components/inputs/autoComplete'
import { FilterableSelect } from '../../../components/selects/simpleSelects'
import CancellableSelect from '../../../components/selects/cancellableSelect'
import { RecommenderInputItemWrapper } from '../../../components/selects/recommenderInput'

import { SettingsFieldColWrapper } from '.'
import { I18N_SETTINGS_NS } from '../../../i18n'

const { Item } = Form

const booleanAnswers = {
	ans_yes: true,
	ans_no: false
}

const modifyingAdjustments = ['free_text']
const getFieldTypeModification = (type, adjustments) => {
	const modifications = Object.entries(adjustments).filter(
		([key, value]) => modifyingAdjustments.includes(key) && value
	)
	return modifications.length ? modifications.reduce((acc, [key]) => `${acc}_${key}`, type) : type
}

const commonMapFieldsToProps = ({ placeholder, name }) => ({
	name: [name],
	...(placeholder && { placeholder })
})

const getSelectorOptions = ({ valid_values }) =>
	valid_values?.map(({ id, value }) => ({
		value: Object.keys(booleanAnswers).includes(id) ? booleanAnswers[id] : id,
		label: value
	}))

const mapFieldsToTextInputProps = ({ max_len, regexp, ...props }, t) => ({
	...commonMapFieldsToProps(props),
	maxLength: max_len,
	formItemProps: {
		rules: [regexp && { pattern: regexp, message: t?.('rules.regexp') }].filter(Boolean)
	}
})

const mapFieldsToRecommenderInputProps = ({ create_only, ...props }) => ({
	...commonMapFieldsToProps(props),
	createOnlyNames: create_only && ['recommender_id', 'recommender_other']
})

const mapFieldsToDatePickerInputProps = ({ ...props }) => ({
	...commonMapFieldsToProps(props),
	formItemProps: {
		getValueProps: value => ({
			value: value ? dayjs(value) : null
		})
	}
})

const mapFieldsToSelectProps = ({ ...props }) => ({
	...commonMapFieldsToProps(props),
	mode: 'single',
	options: getSelectorOptions(props)
})

const mapFieldsToMultiselectProps = ({ min_selected, max_selected, has_nothing, free_text, ...props }, t) => ({
	...commonMapFieldsToProps(props),
	mode: free_text ? 'tags' : 'multiple',
	cancelOptionKey: has_nothing && 'nothing',
	options: [...getSelectorOptions(props), has_nothing && { value: 'nothing', label: t?.('nothing') }].filter(Boolean),
	formItemProps: {
		rules: [
			max_selected && { type: 'array', max: max_selected, message: t?.('rules.max', { max: max_selected }) },
			min_selected && { type: 'array', min: min_selected, message: t?.('rules.min', { min: min_selected }) }
		].filter(Boolean)
	}
})

const settingsComponentsByType = {
	text_input: {
		Component: Input,
		mapFieldToProps: mapFieldsToTextInputProps
	},
	city_search: {
		Component: CityInput, // search is only used for city
		mapFieldToProps: commonMapFieldsToProps
	},
	recommender_search_free_text: {
		Component: () => {}, // all logic is in wrapper
		CustomItemWrapper: RecommenderInputItemWrapper,
		mapFieldToProps: mapFieldsToRecommenderInputProps
	},
	predefined_search: {
		Component: FilterableSelect, // is used for country as select with client-side search
		mapFieldToProps: mapFieldsToSelectProps
	},
	date: {
		Component: DatePicker,
		mapFieldToProps: mapFieldsToDatePickerInputProps
	},
	select: {
		Component: Select,
		mapFieldToProps: mapFieldsToSelectProps
	},
	select_free_text: {
		Component: CustomAutoComplete, // doesn't set value (investor, manager, ....)
		mapFieldToProps: mapFieldsToSelectProps
	},
	multiselect: {
		Component: CancellableSelect,
		mapFieldToProps: mapFieldsToMultiselectProps
	}
}

const getSettingsField = (type, name, adjustments, t, form) => {
	const modifiedType = getFieldTypeModification(type, adjustments)
	const { Component, CustomItemWrapper, mapFieldToProps } =
		settingsComponentsByType?.[Object.keys(settingsComponentsByType).includes(modifiedType) ? modifiedType : type]

	const {
		formItemProps = {},
		createOnlyNames,
		...mappedProps
	} = mapFieldToProps?.({ placeholder: t(`field_placeholder.${name}`), name, ...adjustments }, t) || {}

	const CustomItem = CustomItemWrapper || Item

	if (createOnlyNames) {
		formItemProps.isEditForbidden = createOnlyNames.map(n => !!form.getFieldValue([n])).some(Boolean)
	}

	return (
		Component && (
			<CustomItem name={[name]} style={{ margin: 0 }} {...formItemProps}>
				<Component style={{ width: '100%' }} {...mappedProps} />
			</CustomItem>
		)
	)
}

const Profile = ({ enrichSaveHandler }) => {
	const queryClient = useQueryClient()

	const { t } = useTranslation([I18N_SETTINGS_NS])

	const apiUrl = useApiUrl()
	const { data: { data: settingsFields } = {} } = useCustom({
		url: `${apiUrl}/investors/schema`,
		queryOptions: {
			queryKey: ['schema']
		}
	})

	const {
		form,
		formProps,
		saveButtonProps: { onClick: saveHandler }
	} = useForm({
		id: 'me',
		action: 'edit',
		resource: 'investors',
		warnWhenUnsavedChanges: false,
		onMutationSuccess: async () => {
			await queryClient.invalidateQueries({
				queryKey: ['schema']
			})
		}
	})

	useEffect(() => enrichSaveHandler(saveHandler, 'profile'), [enrichSaveHandler, saveHandler])

	return (
		<Form {...formProps}>
			<Row gutter={[32, 32]}>
				{settingsFields?.map(({ type, name, ...adjustments }) => (
					<SettingsFieldColWrapper key={name} name={name}>
						{getSettingsField(type, name, adjustments, t, form)}
					</SettingsFieldColWrapper>
				))}
			</Row>
		</Form>
	)
}

export default Profile
