function resource(r) {
	return {
		name: r,
		list: `/${r}`,
		create: `/${r}/create`,
		edit: `/${r}/edit/:id`,
		show: `/${r}/show/:id`
	}
}

export const resources = [
	{ ...resource('deals'), name: '', list: '/' },
	resource('coin_transactions'),
	{
		name: 'portfolio_history',
		list: '/portfolio/me/history'
	}
]
