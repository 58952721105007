import axios from 'axios'

import { updateLang, DEFAULT_FALLBACK_LANGUAGE } from '../i18n'

const getAxiosHeaders = async stringifiedTgUserData => {
	return {
		headers: {
			'Telegram-Data': encodeURI(stringifiedTgUserData)
		}
	}
}

export const getAxiosHeadersFromLocalStorage = async () => {
	const stringifiedTgUserData = localStorage.getItem('tgUserData')
	return await getAxiosHeaders(stringifiedTgUserData)
}

const getUiLang = async stringifiedTgUserData => {
	try {
		const {
			data: { ui_lang = DEFAULT_FALLBACK_LANGUAGE }
		} = await axios.get(
			`${process.env.REACT_APP_BOT_API_URL}/investors/me`,
			await getAxiosHeaders(stringifiedTgUserData)
		)

		return ui_lang
	} catch (err) {
		if (err?.response?.status === 401) throw new Error()
		return DEFAULT_FALLBACK_LANGUAGE
	}
}

export const authProvider = {
	login: async tgUserData => {
		if (!tgUserData) return { success: false }

		const stringifiedData = JSON.stringify(tgUserData)
		localStorage.setItem('tgUserData', stringifiedData)

		try {
			const { status, data } = await axios.post(
				`${process.env.REACT_APP_BOT_API_URL}/check_auth`,
				null,
				await getAxiosHeaders(stringifiedData)
			)

			if (status !== 200) return { success: false }

			const ui_lang = await getUiLang(stringifiedData)

			localStorage.setItem('adUserData', JSON.stringify({ ...data, ui_lang }))

			await updateLang(ui_lang)
			return { success: true, redirectTo: '/' }
		} catch (err) {
			const status = err?.response?.status

			if (status === 403) {
				localStorage.removeItem('tgUserData')

				return {
					success: true,
					redirectTo: '/unapproved'
				}
			}

			return {
				success: false,
				error: {
					message: 'Login Error',
					name: `Please, contact bot: @${process.env.REACT_APP_BOT_NAME}`
				}
			}
		}
	},
	logout: async () => {
		localStorage.removeItem('tgUserData')

		return {
			success: true,
			redirectTo: '/login'
		}
	},
	onError: async error => {
		console.error('Auth Provider Error', error)

		return {
			logout: false,
			error
		}
	},
	check: async () => {
		const tgUserData = localStorage.getItem('tgUserData')

		if (tgUserData) {
			const ui_lang = await getUiLang(tgUserData)
			await updateLang(ui_lang)

			return {
				authenticated: true
			}
		}

		console.error('Auth check failed')
		return {
			authenticated: false,
			error: {
				message: 'Login failed',
				name: 'User data not found'
			},
			logout: true,
			redirectTo: '/login'
		}
	},
	getPermissions: async () => null,
	getIdentity: async () => {
		const tgUserData = localStorage.getItem('tgUserData')
		const adUserData = localStorage.getItem('adUserData')

		if (tgUserData && adUserData) {
			return { ...JSON.parse(tgUserData), ...JSON.parse(adUserData) }
		}

		return null
	}
}
