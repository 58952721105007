import dayjs from 'dayjs'
import { useState } from 'react'
import Icon from '@ant-design/icons'
import { useTable } from '@refinedev/antd'
import { useTranslation } from 'react-i18next'
import { useApiUrl, useCustom } from '@refinedev/core'
import { Grid, Card, Space, Table, Segmented, Button, Row, Col, Typography, Tag } from 'antd'

import ValueCard from '../components/valueCard'
import QuarterColumn from '../components/charts/quarterColumn'

import { formatCurrency, getStartCase } from '../utils/formatting'
import { greenBright, greenLight, greyBackground, redBright, redLight, violetLight, yellowLight } from '../theme'

import { ReactComponent as GainIcon } from '../icons/portfolioGain.svg'
import { ReactComponent as DeployedIcon } from '../icons/portfolioDeployed.svg'
import { ReactComponent as ValuationIcon } from '../icons/portfolioValuation.svg'

import { I18N_PORTFOLIO_NS } from '../i18n'
import Warning from '../components/warning'
import ControlPanelHeader from '../containers/controlPanelHeader'

const { Link } = Typography
const { useBreakpoint } = Grid

const PORTFOLIO_DEALS_PER_PAGE = 10

const getCommonTableColumns = (keys, t, render) =>
	keys.map(key => ({ key, dataIndex: key, title: t ? t(key) : key, render }))

// const valuationMethods = ['equity', 'safe', 'accountant', 'revenue_multiplier']
const valuationMethods = ['equity', 'safe']
const valuationMethodsLabels = { safe: 'SAFE', revenue_multiplier: 'Revenue' }
const valuationMethodsOptions = valuationMethods.map(m => ({
	label: valuationMethodsLabels[m] || getStartCase(m),
	value: m
}))

const getFormattedGainSubtitle = (valuation, deployed) =>
	`${new Intl.NumberFormat('en-US', {
		signDisplay: 'exceptZero'
	}).format(Math.round(((valuation - deployed) * 100) / deployed))}%`

const Summary = ({ resource, valuationMethod, valuationMethodFilter, setValuationMethod, fundCardData }) => {
	const valuationMethodQuery = { valuation_type: valuationMethod }

	const apiUrl = useApiUrl()
	const { data: { data: { deployed, valuation, gain } = {} } = {} } = useCustom({
		url: `${apiUrl}/${resource}/portfolio`,
		errorNotification: false,
		config: {
			query: {
				...valuationMethodQuery
			}
		}
	})

	const {
		tableProps: { dataSource }
	} = useTable({
		resource: `${resource}/portfolio/history`,
		errorNotification: false,
		pagination: {
			mode: 'server',
			pageSize: 10
		},
		filters: { permanent: [valuationMethodFilter] }
	})

	const { t } = useTranslation([I18N_PORTFOLIO_NS])

	const { xs } = useBreakpoint()

	const XS_WIDTH = 316
	const cardWidth = xs && XS_WIDTH

	const deployedCard = (
		<ValueCard
			title={t('deployed')}
			value={formatCurrency(deployed)}
			color={greyBackground}
			icon={<Icon component={DeployedIcon} style={{ fontSize: 24 }} />}
			width={cardWidth}
		/>
	)

	const valuationCard = (
		<ValueCard
			title={t('valuation')}
			value={formatCurrency(valuation)}
			color={yellowLight}
			icon={<Icon component={ValuationIcon} style={{ fontSize: 24 }} />}
			width={cardWidth}
		/>
	)

	const gainCard = (
		<ValueCard
			title={t('gain')}
			value={formatCurrency(gain)}
			// subValue={valuation && deployed && getFormattedGainSubtitle(valuation, deployed)}
			color={gain >= 0 ? greenLight : redLight}
			// subValueColor={gain >= 0 ? greenBright : redBright}
			icon={<Icon component={GainIcon} style={{ fontSize: 24 }} />}
			width={cardWidth}
		/>
	)

	const fundTotalCard = fundCardData && (
		<ValueCard
			title={t('fund_total')}
			value={formatCurrency(fundCardData.ad_committed)}
			color={violetLight}
			width={cardWidth}
		/>
	)

	return (
		<Card>
			<Space direction='vertical' size='large' style={{ width: '100%' }}>
				<Row gutter={[16, 16]} justify='space-between'>
					<Col>
						<Segmented
							style={{ height: 'fit-content' }}
							onChange={setValuationMethod}
							defaultValue={valuationMethod}
							options={valuationMethodsOptions}
						/>
					</Col>
					<Col>
						<Button href={t('help_button_url')} target='_blank'>
							{t('help_button')}
						</Button>
					</Col>
				</Row>
				<Row gutter={[16, 32]} justify='space-between' style={{ width: '100%', margin: 0 }}>
					<Row gutter={[16, 16]} style={{ width: 'fit-content' }}>
						<Col>{deployedCard}</Col>
						<Col>{valuationCard}</Col>
						<Col>{gainCard}</Col>
						{fundTotalCard && <Col>{fundTotalCard}</Col>}
					</Row>
					<Col style={{ height: 120 }}>{dataSource?.length ? <QuarterColumn data={dataSource} /> : null}</Col>
				</Row>
			</Space>
		</Card>
	)
}

const PortfolioDealsTable = ({ resource, isFund, valuationMethodFilter, searchText }) => {
	const { t } = useTranslation([I18N_PORTFOLIO_NS])

	const columns = [
		{
			key: 'deal_full_name',
			title: t('deal_name'),
			dataIndex: 'deal_full_name',
			render: (x, { key, deal_type, ad_series }) =>
				deal_type === 'fund' ? (
					<Link href={`/funds/${key}`}>
						<Row gutter={8}>
							<Col>{x}</Col>
							<Col>
								<Tag color='purple'>Fund</Tag>
							</Col>
						</Row>
					</Link>
				) : (
					<Link href={`/deals/${key}`}>
						<Space size={4}>
							{x}
							<sup
								style={{
									fontSize: 8,
									lineHeight: 0,
									verticalAlign: 'super',
									color: ad_series ? 'green' : 'orange'
								}}
							>
								{ad_series ? '1' : '2'}
							</sup>
						</Space>
					</Link>
				)
		},
		{
			sorter: {},
			key: 'entry_date',
			title: t('entry_date'),
			dataIndex: 'entry_date',
			render: x => (x ? dayjs(x).format('MMM, YYYY') : '-')
		},
		// ...(isFund ? getCommonTableColumns(['fund_total', 'fund_undeployed'], t, v => formatCurrency(v)) : []),
		{
			sorter: {},
			key: 'deployed',
			title: t('deployed'),
			dataIndex: 'deployed',
			render: v => formatCurrency(v)
		},
		...getCommonTableColumns(['valuation'], t, v => formatCurrency(v)),
		{
			key: 'gain',
			title: t('gain'),
			dataIndex: 'gain',
			render: (gain, { deployed, valuation }) => {
				const gainSubtitle =
					valuation !== undefined && deployed && getFormattedGainSubtitle(valuation, deployed)
				return `${formatCurrency(gain)}` + (gainSubtitle ? ` (${gainSubtitle})` : '')
			}
		}
	]

	const nameFilter = {
		field: 'search',
		operator: 'eq',
		value: searchText
	}

	const {
		tableProps: { dataSource, ...tableProps }
	} = useTable({
		resource: `${resource}/portfolio/deals`,
		errorNotification: false,
		pagination: {
			mode: 'server',
			pageSize: PORTFOLIO_DEALS_PER_PAGE
		},
		filters: { permanent: [valuationMethodFilter, nameFilter.value && nameFilter].filter(Boolean) },
	})

	return (
		dataSource && (
			<Table
				columns={columns}
				dataSource={dataSource.map(({ deal_id, ...v }) => ({ key: deal_id, ...v }))}
				{...tableProps}
				pagination={{
					...tableProps.pagination,
					position: ['bottomLeft'],
					showSizeChanger: true
				}}
			/>
		)
	)
}

export const PortfolioPageBody = ({ resource, isFund, current, currentTitleComponent, fundCardData }) => {
	const [valuationMethod, setValuationMethod] = useState(valuationMethods[0])
	const [searchText, setSearchText] = useState('')

	const valuationMethodFilter = {
		field: 'valuation_type',
		operator: 'eq',
		value: valuationMethod
	}

	return (
		<Space direction='vertical' size='large' style={{ width: '100%' }}>
			<ControlPanelHeader onSearch={setSearchText} current={current} currentTitleComponent={currentTitleComponent} />
			<Space direction='vertical' style={{ width: '100%' }}>
				<Summary
					resource={resource}
					fundCardData={fundCardData}
					valuationMethod={valuationMethod}
					setValuationMethod={setValuationMethod}
					valuationMethodFilter={valuationMethodFilter}
				/>
				<PortfolioDealsTable
					isFund={isFund}
					resource={resource}
					searchText={searchText}
					valuationMethodFilter={valuationMethodFilter}
				/>
			</Space>
			<Warning />
		</Space>
	)
}

const PortfolioPage = () => {
	return <PortfolioPageBody resource='investors/me' isFund={false} />
}

export default PortfolioPage
