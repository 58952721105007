import { Row, Col } from 'antd'

import ItemCard from './itemCard'

const ItemsGrid = ({ items, onItemClick }) => {
	return (
		<Row gutter={[16, 16]}>
			{items?.map(({ key, ...item }) => (
				<Col key={key} sx={24} sm={24} md={24} lg={24} xl={12} xxl={12} style={{ width: '100%' }}>
					<ItemCard {...item} onClick={() => onItemClick(item)}></ItemCard>
				</Col>
			))}
		</Row>
	)
}

export default ItemsGrid
