import { useApiUrl } from '@refinedev/core'
import { useTranslation } from 'react-i18next'
import { Card, Typography, Space, Row, Col, Image, Button, Divider } from 'antd'

import TagsRow from '../../components/tagsRow'
import Progress from '../../components/progress'

import { grey, greyBorder } from '../../theme'

import { I18N_DEAL_NS } from '../../i18n'

const { Title, Paragraph, Text } = Typography

export const ItemImage = ({ itemPath, maxHeight }) => {
	const apiUrl = useApiUrl()
	return (
		<Image
            preview={false}
            fallback={null}
            placeholder={null}
            src={`${apiUrl}/${itemPath}`}
            style={{
                maxHeight: maxHeight || '48px',
                maxWidth: '100%',
                objectFit: 'contain',
				height: 'auto'
            }}
            wrapperStyle={{ marginTop: 'auto' }}
            onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.style.display = 'none'
            }}
        />
	)
}

const ItemCard = ({
	title,
	onClick,
	progress,
	children,
	headerTags,
	description,
	descriptors,
	onButtonClick,
	buttonTextKey,
	bottomItemImagePath
}) => {
	const { t } = useTranslation([I18N_DEAL_NS])

	return (
		<Card
			hoverable
			onClick={onClick}
			bodyStyle={{ height: '100%' }}
			style={{ height: '100%', border: `1px solid ${greyBorder}` }}
		>
			<Space
				size='middle'
				direction='vertical'
				style={{ display: 'flex', justifyContent: 'space-between', width: '100%', height: '100%' }}
			>
				<Row gutter={[32, 32]}>
					<Col span={16}>
						<Space direction='vertical' size='large' style={{ width: '100%' }}>
							<Space direction='vertical' size='small'>
								<Title level={3} style={{ margin: 0 }}>
									{title}
								</Title>
								{description && <Paragraph style={{ margin: 0, color: grey }}>{description}</Paragraph>}
							</Space>
							{descriptors && (
								<Space direction='vertical' size='small'>
									{descriptors.map(({ title, value }) => (
										<Paragraph style={{ margin: 0 }} key={title}>
											<Text strong>{title}:</Text> {value}
										</Paragraph>
									))}
								</Space>
							)}
						</Space>
					</Col>
					<Col span={8}>{headerTags && <TagsRow tags={headerTags} />}</Col>
				</Row>
				<Space direction='vertical' size='middle' style={{ width: '100%' }}>
					<Row gutter={[32, 32]}>
						<Col span={16} style={{ display: 'flex', alignItems: 'end' }}>
							{progress && <Progress {...progress} />}
						</Col>
						<Col span={8} style={{ display: 'flex', justifyContent: 'end' }}>
							{bottomItemImagePath && <ItemImage itemPath={bottomItemImagePath} />}
						</Col>
					</Row>
					{onButtonClick && (
						<>
							<Divider style={{ margin: 0 }} />
							<Row justify='start'>
								<Button style={{ zIndex: 1 }} onClick={onButtonClick}>
									{t(buttonTextKey)}
								</Button>
							</Row>
						</>
					)}
					{children}
				</Space>
			</Space>
		</Card>
	)
}

export default ItemCard
